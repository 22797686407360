<template>
  <v-container>
    <v-expansion-panels mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header> <div class="header-text">Trip Time Messages</div></v-expansion-panel-header>
        <v-expansion-panel-content>
          <trip-time-messages></trip-time-messages>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Blocked Dates</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <blocked-dates></blocked-dates>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Special Dates</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <special-dates></special-dates>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Lead Days</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <lead-days></lead-days>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import TripTimeMessages from './TripTimeMessages.vue';
import BlockedDates from './BlockedDates.vue';
import SpecialDates from './SpecialDates.vue';
import LeadDays from './LeadDays.vue';

export default {
  mixins: [],
  components: { TripTimeMessages, BlockedDates, SpecialDates, LeadDays },
  data: () => ({
    config: {
      leadDays: {},
    },
  }),
};
</script>

<style scoped>
.header-text {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
</style>
