<template>
  <v-form :readonly="!canEditSettings">
    <v-row dense>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn fab small color="primary" @click="addTTM()" v-if="!editedTTM && canEditSettings">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn v-else v-show="editedTTM" fab small color="primary" @click="addTTM()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-scroll-x-transition>
          <v-btn v-show="selected.length" class="mx-2" dark color="red" @click="deleteTTM()" v-if="canEditSettings">
            <v-icon>mdi-delete</v-icon>Delete {{ selected.length }} Trip Time Message{{
              selected.length > 1 ? 's' : ''
            }}
          </v-btn>
        </v-scroll-x-transition>
      </v-col>
    </v-row>
    <v-row dense v-if="editedTTM">
      <v-col cols="12" md="12">
        <v-card>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">
                <time-picker
                  label="Begin Time"
                  :value="editedTTM.begin"
                  v-model="$v.form.begin.$model"
                  required
                  :readonly="!canEditSettings"
                  :error-descriptions="handleErrors($v.form.begin)"
                  @blur="$v.form.begin.$touch()"
                ></time-picker>
              </v-col>
              <v-col cols="12" md="6">
                <time-picker
                  label="End Time"
                  :value="editedTTM.end"
                  v-model="$v.form.end.$model"
                  required
                  :readonly="!canEditSettings"
                  :error-descriptions="handleErrors($v.form.end)"
                  @blur="$v.form.end.$touch()"
                ></time-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  label="Message"
                  :value="editedTTM.description"
                  v-model="$v.form.description.$model"
                  required
                  :error-descriptions="handleErrors($v.form.description)"
                  @blur="$v.form.description.$touch()"
                ></v-textarea>
              </v-col>
            </v-row>
            <h5>Trip Types</h5>
            <v-row dense>
              <v-col cols="12" md="3" class="checkbox">
                <v-checkbox v-model="selectAllTypes" label="All" @change="handleAllTypes"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" v-for="(type, i) in tripTypes" :key="i" class="checkbox">
                <v-checkbox
                  :value="type.id"
                  v-model="$v.form.tripTypeIds.$model"
                  :label="type.name"
                  @change="$v.form.tripTypeIds.$touch()"
                  multiple
                  @click="handleTripTypeSelected"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-btn color="green" dark @click="save" class="save-btn" v-if="canEditSettings"> save </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-data-table
          @click:row="editTTM($event)"
          fixed-header
          :headers="headers"
          :items="tripTimeMessages"
          item-key="id"
          show-select
          v-model="selected"
        >
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox :value="isSelected" @input="select($event)" v-ripple></v-simple-checkbox>
          </template>
          <template #[`item.tripTypes`]="{ item }">
            <p class="mb-0">{{ item.tripTypeIds.map((e) => tripTypesById[e].name).join(', ') }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import { mapActions, mapGetters } from 'vuex';
import { GET_TRIP_DATE_TIMES, SAVE_TRIP_DATE_TIME, DELETE_TRIP_DATE_TIMES } from '@/store/modules/Config/actions';
import TimePicker from '@/components/TimePicker.vue';

const newTTM = {
  type: 'time',
  begin: '',
  end: '',
  description: '',
  tripTypeIds: [],
};

export default {
  mixins: [validationMixin],
  components: { TimePicker },
  props: {},
  data: () => ({
    handleErrors,
    tripTimeMessages: [],
    id: null,
    form: newTTM,
    headers: [
      { text: 'Begin Time', value: 'begin' },
      { text: 'End Time', value: 'end' },
      { text: 'Message', value: 'description' },
      { text: 'Trip Types', value: 'tripTypes' },
    ],
    editedTTM: null,
    selected: [],
    selectAllTypes: false,
    selectAllEvents: false,
  }),
  validations: {
    form: {
      begin: { required },
      end: { required },
      description: { required },
      tripTypeIds: { required },
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
    ...mapGetters('tripType', ['tripTypes', 'tripTypesById']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_TRIP_DATE_TIMES, SAVE_TRIP_DATE_TIME, DELETE_TRIP_DATE_TIMES]),
    async fetchItems() {
      this.tripTimeMessages = await this.getTripDateTimes('time');
    },
    clear() {
      this.form = newTTM;
      this.$refs.aform.clear();
    },
    addTTM() {
      if (!this.editedTTM) this.editedTTM = { id: 0, ...newTTM };
      else this.editedTTM = null;
    },
    editTTM(ttm) {
      this.editedTTM = ttm;
    },
    handleAllTypes() {
      if (this.$v.form.tripTypeIds.$model.length == this.tripTypes.length) this.$v.form.tripTypeIds.$model = [];
      else this.$v.form.tripTypeIds.$model = this.tripTypes.map((e) => e.id);
      this.handleTripTypeSelected();
    },
    handleTripTypeSelected() {
      if (this.$v.form.tripTypeIds.$model.length == this.tripTypes.length) this.selectAllTypes = true;
      else this.selectAllTypes = false;
    },
    async save() {
      if (this.$v.form.$invalid) {
        this.$myalert.error('Please complete all fields');
        return;
      }
      try {
        const r = await this.saveTripDateTime(this.$v.form.$model);
        if (r && r.id) {
          this.$myalert.success('Trip Time Message saved');
          this.editedTTM = null;
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.description);
      }
    },
    async deleteTTM() {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          this.selected.length > 1 ? 'these Trip Time Messages' : 'this Trip Time Message'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteTripDateTimes(this.selected.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${this.selected.length > 1 ? 'Trip Time Messages' : 'Trip Time Message'} deleted`);
          this.editedTTM = null;
          this.selected = [];
          this.fetchItems();
        }
      }
    },
  },
  watch: {
    editedTTM: {
      immediate: true,
      deep: true,
      handler: function (editedTTM) {
        this.form = { ...newTTM, ...editedTTM };
      },
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
